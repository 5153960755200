import { Box } from '@mui/material';
import Assets from 'assets/assets';
import React from 'react';

const LogoLoader = () => {
    return (
        <Box
            component='div'
            sx={{
                width: 'fit-content',
                fontSize: '40px',
                fontFamily: 'system-ui, sans-serif',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: '#0000',
                WebkitTextStroke: '1px #fff',
                background: `
          radial-gradient(0.71em at 50% 1em, ${Assets.colors.white} 99%, #0000 101%) calc(50% - 1em) 1em / 2em 200% repeat-x text,
          radial-gradient(0.71em at 50% -0.5em, #0000 99%, ${Assets.colors.white} 101%) 50% 1.5em / 2em 200% repeat-x text
        `,
                animation: `
          l10-0 0.8s linear infinite alternate,
          l10-1 4s linear infinite
        `,
                position: 'relative',
                '&:before': {
                    content: '"Fluxgen"',
                },
                '@keyframes l10-0': {
                    to: {
                        backgroundPositionX: '50%, calc(50% + 1em)',
                    },
                },
                '@keyframes l10-1': {
                    to: {
                        backgroundPositionY: '-0.5em, 0',
                    },
                },
            }}
        />
    );
};

export default LogoLoader;
