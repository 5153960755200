import React from 'react';

import { Dock, DockIcon } from '@/components/magicui/dock';
import { Box, Button, ButtonBase } from '@mui/material';
import Assets from 'assets/assets';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavbarItem } from 'enums/navbarEnum';

const WebNavbarLinkButton = ({
    item,
    handleNavigation,
    pathname,
}: {
    item: NavbarItem;
    handleNavigation: any;
    pathname: string;
}) => {
    return (
        <Button
            sx={{
                color: pathname === NavbarItem.url(item) ? '#00374a' : '#000',
                fontSize: '16px',
                fontWeight: pathname === NavbarItem.url(item) ? 600 : 500,
                lineHeight: '24px',
                textTransform: 'none',
                display: 'flex',
                flexDirection: 'column',

                '&::after': {
                    content: '""',
                    background: `#00374a`,
                    position: 'absolute',
                    left: '10px',
                    bottom: '20%',
                    width: pathname === NavbarItem.url(item) ? '55%' : '0px',
                    height: '1.5px',
                    transition: 'all 0.3s ease',
                },

                '&:hover': {
                    color: '#00374a',
                },
            }}
            onClick={() => handleNavigation(NavbarItem.url(item))}
        >
            {NavbarItem.displayName(item)}
        </Button>
    );
};

export function WebNavBar() {
    const location = useLocation();
    const pathname = location.pathname;

    const navigate = useNavigate();

    const handleNavigation = (url: string) => {
        navigate(url);
    };

    return (
        <Box
            sx={{
                overflow: 'hidden',
                width: '100%',
                display: { xs: 'none', md: 'flex' },
                my: 1,
            }}
        >
            <Dock direction='middle'>
                {NavbarItem.values()
                    .slice(0, 2)
                    .map((item, navIndex) => (
                        <DockIcon key={navIndex + 'ITEM'}>
                            <WebNavbarLinkButton
                                item={item}
                                handleNavigation={handleNavigation}
                                pathname={pathname}
                            />
                        </DockIcon>
                    ))}
                <ButtonBase>
                    <img src={Assets.images.logoDark} alt='logo' width={150} />
                </ButtonBase>
                {NavbarItem.values()
                    .slice(2, 4)
                    .map((item, navIndex) => (
                        <DockIcon key={navIndex + 'ITEM'}>
                            <WebNavbarLinkButton
                                item={item}
                                handleNavigation={handleNavigation}
                                pathname={pathname}
                            />
                        </DockIcon>
                    ))}
            </Dock>
        </Box>
    );
}
