import { RouterProvider } from 'react-router-dom';
import { appRouter } from 'router/AppRouter';
import { ParallaxProvider } from 'react-scroll-parallax';
import LocomotiveScroll from 'locomotive-scroll';
import { ThemeProvider } from '@mui/material';
import CustomTheme from 'theme/CustomTheme';

function App() {
    //TODO: Check for locomotive scroll
    //eslint-disable-next-line
    const locomotiveScroll = new LocomotiveScroll();

    return (
        <>
            <div
                className='hello'
                style={{
                    display: 'none',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 1000,
                    backgroundRepeat: 'repeat',
                    mixBlendMode: 'multiply',
                    pointerEvents: 'none',
                }}
            />
            <ThemeProvider theme={CustomTheme}>
                <ParallaxProvider>
                    <RouterProvider router={appRouter} />
                </ParallaxProvider>
            </ThemeProvider>
        </>
    );
}

export default App;
