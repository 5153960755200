import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, ButtonBase, Divider, Drawer } from '@mui/material';
import { WebNavBar } from './WebNavbar';
import Assets from 'assets/assets';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useNavigate } from 'react-router-dom';
import { NavbarItem } from 'enums/navbarEnum';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children?: React.ReactElement<any>;
}

function ElevationScroll(props: Props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return children
        ? React.cloneElement(children, {
              elevation: trigger ? 4 : 0,
              style: {
                  backgroundColor: trigger ? 'white' : 'transparent',
                  backdropFilter: trigger ? 'blur(20px)' : 'none',
              },
          })
        : null;
}

export default function NavBar(props: Props) {
    const { window } = props;

    const drawerWidth = 240;

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const navigate = useNavigate();

    const handleNavigation = (url: string) => {
        navigate(url);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant='h6' sx={{ my: 2 }}>
                MUI
            </Typography>
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {NavbarItem.values().map((item, navIndex) => (
                    <Button
                        onClick={() => handleNavigation(NavbarItem.url(item))}
                        key={item}
                    >
                        {item}
                    </Button>
                ))}
            </Box>
        </Box>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <ElevationScroll {...props}>
                <AppBar component='nav'>
                    <Toolbar>
                        <IconButton
                            size='large'
                            edge='start'
                            aria-label='open drawer'
                            sx={{ mr: 2, display: { md: 'none' } }}
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon style={{ fill: Assets.colors.primary }} />
                        </IconButton>

                        <ButtonBase sx={{ display: { md: 'none' } }}>
                            <img
                                src={Assets.images.logoDark}
                                alt='logo'
                                width={120}
                            />
                        </ButtonBase>

                        <WebNavBar />
                    </Toolbar>
                </AppBar>
            </ElevationScroll>

            <nav>
                <Drawer
                    container={container}
                    variant='temporary'
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
}
