enum NavbarItem {
    HOME = 'HOME',
    ABOUT = 'ABOUT',
    SOLUTIONS = 'SOLUTIONS',
    CONTACT = 'CONTACT',
}

namespace NavbarItem {
    export function displayName(item: NavbarItem): string {
        switch (item) {
            case NavbarItem.HOME:
                return 'Home';
            case NavbarItem.ABOUT:
                return 'About Us';
            case NavbarItem.CONTACT:
                return 'Contact Us';
            case NavbarItem.SOLUTIONS:
                return 'Solutions';
        }
    }

    export function url(item: NavbarItem): string {
        switch (item) {
            case NavbarItem.HOME:
                return '/';
            case NavbarItem.ABOUT:
                return '/about';
            case NavbarItem.CONTACT:
                return '/contact';
            case NavbarItem.SOLUTIONS:
                return '/solutions';
        }
    }

    export function values(): NavbarItem[] {
        return [
            NavbarItem.HOME,
            NavbarItem.ABOUT,
            NavbarItem.SOLUTIONS,
            NavbarItem.CONTACT,
        ];
    }
}

export { NavbarItem };
