'use client';

import { useEffect, useState } from 'react';
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';

import { cn } from '@/lib/utils';

interface WordRotateProps {
    words: string[];
    duration?: number;
    framerProps?: HTMLMotionProps<'h1'>;
    className?: string;
    wrapperClassName?: string;
}

export default function WordRotate({
    words,
    duration = 2000,
    framerProps = {
        initial: { opacity: 0, y: -50 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 50 },
        transition: { duration: 0.25, ease: 'easeOut' },
    },
    wrapperClassName,
    className,
}: WordRotateProps) {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, duration);

        // Clean up interval on unmount
        return () => clearInterval(interval);
    }, [words, duration]);

    return (
        <div
            className={cn(
                'overflow-hidden inline-block sm:h-9 md:h-[2.75rem] sm:translate-y-2 translate-y-0',
                wrapperClassName
            )}
        >
            <AnimatePresence mode='wait'>
                <motion.h1
                    key={words[index]}
                    className={cn(className)}
                    {...framerProps}
                >
                    {words[index]}
                </motion.h1>
            </AnimatePresence>
        </div>
    );
}
