import FallbackLoader from 'components/loader/FallbackLoader';
import React from 'react';
import { Outlet } from 'react-router-dom';

export interface IMainLayoutProps {}

export default function ContactLayout(props: IMainLayoutProps) {
    return (
        <>
            <React.Suspense fallback={<FallbackLoader />}>
                <Outlet />
            </React.Suspense>
        </>
    );
}
