import { Box } from '@mui/material';
import Assets from 'assets/assets';
import LogoLoader from 'components/loader/LogoLoader';
import React from 'react';

const FallbackLoader = () => {
    return (
        <Box
            sx={{
                background: Assets.colors.primary,
                display: 'grid',
                placeItems: 'center',
                width: '100vw',
                height: '100vh',
                position: 'absolute',
                zIndex: 2000,
                top: '00%',
            }}
        >
            <LogoLoader />
        </Box>
    );
};

export default FallbackLoader;
