import React, { PropsWithChildren, useRef } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion';

import { cn } from '@/lib/utils';

export interface DockProps extends VariantProps<typeof dockVariants> {
    className?: string;
    magnification?: number;
    distance?: number;
    direction?: 'top' | 'middle' | 'bottom';
    children: React.ReactNode;
}

const DEFAULT_MAGNIFICATION = 120;
const DEFAULT_DISTANCE = 200;

const dockVariants = cva('mx-auto w-max h-[58px] flex');

const Dock = React.forwardRef<HTMLDivElement, DockProps>(
    (
        {
            className,
            children,
            magnification = DEFAULT_MAGNIFICATION,
            distance = DEFAULT_DISTANCE,
            direction = 'bottom',
            ...props
        },
        ref
    ) => {
        const mousex = useMotionValue(Infinity);

        const renderChildren = () => {
            return React.Children.map(children, (child: any) => {
                return React.cloneElement(child, {
                    mousex: mousex,
                    magnification: magnification,
                    distance: distance,
                });
            });
        };

        return (
            <motion.div
                ref={ref}
                onMouseMove={(e) => mousex.set(e.pageX)}
                onMouseLeave={() => mousex.set(Infinity)}
                {...props}
                className={cn(dockVariants({ className }), {
                    'items-start': direction === 'top',
                    'items-center': direction === 'middle',
                    'items-end': direction === 'bottom',
                })}
            >
                {renderChildren()}
            </motion.div>
        );
    }
);

Dock.displayName = 'Dock';

export interface DockIconProps {
    size?: number;
    magnification?: number;
    distance?: number;
    mousex?: any;
    className?: string;
    children?: React.ReactNode;
    props?: PropsWithChildren;
}

const DockIcon = ({
    size,
    magnification = DEFAULT_MAGNIFICATION,
    distance = DEFAULT_DISTANCE,
    mousex,
    className,
    children,
    ...props
}: DockIconProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const distanceCalc = useTransform(mousex, (val: number) => {
        const bounds = ref.current?.getBoundingClientRect() ?? {
            x: 0,
            width: 0,
        };

        return val - bounds.x - bounds.width / 2;
    });

    let widthSync = useTransform(
        distanceCalc,
        [-distance, 0, distance],
        [140, magnification, 140]
    );

    let width = useSpring(widthSync, {
        mass: 0.1,
        stiffness: 150,
        damping: 12,
    });

    return (
        <motion.div
            ref={ref}
            style={{ width }}
            className={cn(
                'flex aspect-square cursor-pointer items-center justify-center rounded-full',
                className
            )}
            {...props}
        >
            {children}
        </motion.div>
    );
};

DockIcon.displayName = 'DockIcon';

export { Dock, DockIcon, dockVariants };
