import React from 'react';
import { Box } from '@mui/material';
import Footer from 'components/footer/Footer';
import Navbar from 'components/navbar/Navbar';
import { Outlet } from 'react-router-dom';
import FallbackLoader from 'components/loader/FallbackLoader';

export interface IMainLayoutProps {}

export default function MainLayout(props: IMainLayoutProps) {
    return (
        <>
            <Navbar />
            <Box style={{ width: '100%', overflow: 'hidden' }}>
                <React.Suspense fallback={<FallbackLoader />}>
                    <Outlet />
                </React.Suspense>
            </Box>
            <Footer />
        </>
    );
}
