import { Box, Container, Divider, Link, Typography } from '@mui/material';
import WordRotate from '@/components/magicui/word-rotate';
import Assets from 'assets/assets';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import { Icon } from '@iconify/react';

const MadeWithRotate = () => {
    return (
        <Typography
            component={'span'}
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '7px',
                color: '#C3C3C3',
                fontSize: '16px',
                ml: '24px',
                fontWeight: 600,
                whiteSpace: 'nowrap',
            }}
        >
            Made with
            <Box
                component={'span'}
                sx={{ transform: { md: 'translateY(-10px)' } }}
            >
                <WordRotate
                    wrapperClassName='sm:h-[16px] md:h-[16px]'
                    className='text-1xl'
                    words={[
                        '🥟',
                        '⛳',
                        '🍞',
                        '🍷',
                        '🍺',
                        '🍰',
                        '🔒',
                        '🔮',
                        '🍩',
                        '🥖',
                        '🥨',
                        '🍤',
                        '🧄',
                        '🛌',
                        '🛹',
                        '💜',
                        '🥥',
                        '☕',
                    ]}
                />
            </Box>
            on&nbsp;&nbsp;🌎
        </Typography>
    );
};

function RightMenu() {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                gap: '12px',
                flexDirection: 'column',
                alignItems: {
                    xs: 'center',
                    sm: 'end',
                },
                color: 'white',
                textUnderlineOffset: '5px',
            }}
        >
            <Typography
                sx={{
                    width: { xs: 'fit-content', sm: '70%', md: '50%' },
                    fontSize: {
                        md: 28,
                        xs: 20,
                    },
                    fontWeight: 600,
                    color: 'white',
                    mb: 1,
                    textAlign: 'left',
                }}
            >
                Get in Touch
            </Typography>
            <Typography
                fontSize={14}
                fontWeight={'500'}
                color={'#E3E3E3'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                    gap: '12px',
                }}
            >
                <a
                    target={'_blank'}
                    rel='noreferrer'
                    href={'https://maps.app.goo.gl/3ETwzcWFD7Y446dA6'}
                    style={{ display: 'flex', alignItems: 'start' }}
                >
                    <LocationOnIcon
                        sx={{ fill: 'white', marginRight: '10px', mt: '4px' }}
                        fontSize='small'
                    />
                    <Typography
                        sx={{
                            display: 'inline-block',
                            maxWidth: '252px',
                            fontSize: '16px',
                        }}
                    >
                        2nd Floor, Garuda Bhive, BMTC Complex, Kuvempu Nagar,
                        Stage 2, BTM Layout, Bengaluru, Karnataka - 560068
                    </Typography>
                </a>
                <Box sx={{ fontSize: '16px' }}>
                    <PhoneIcon
                        sx={{ fill: 'white', marginRight: 1 }}
                        fontSize='small'
                    />{' '}
                    +91 73560 08082
                </Box>
                <Box>
                    <EmailIcon
                        sx={{ fill: 'white', marginRight: '14px' }}
                        fontSize='small'
                    />
                    <a
                        target={'_blank'}
                        rel='noreferrer'
                        href='mailto:contact@fluxgentech.com'
                        style={{ fontSize: '16px' }}
                    >
                        contact@fluxgentech.com
                    </a>
                </Box>
            </Typography>
        </Box>
    );
}

function SocialMediaMenu() {
    return (
        <Box
            sx={{
                mt: '8px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: {
                    xs: 'center',
                },
                justifyContent: 'center',
            }}
            gap={'12px'}
        >
            <Link target='_blank' href='https://www.facebook.com/FluxGenTech'>
                <Icon
                    icon='raphael:facebook'
                    style={{ color: 'white', fontSize: '20px' }}
                />
            </Link>
            <Link target='_blank' href='https://x.com/FluxGenTech'>
                <Icon
                    icon='fa6-brands:square-x-twitter'
                    style={{ color: 'white', fontSize: '20px' }}
                />
            </Link>
            <Link
                target='_blank'
                href='https://www.linkedin.com/company/fluxgen-sustainable-technologies/'
            >
                <Icon
                    icon='uil:linkedin'
                    style={{ color: 'white', fontSize: '20px' }}
                />
            </Link>
        </Box>
    );
}

const Footer = () => {
    return (
        <Box sx={{ bgcolor: Assets.colors.primary, pb: 4, pt: 6 }}>
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'start',
                        flexDirection: { xs: 'column', sm: 'row' },
                        rowGap: '20px',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: { xs: '100px', sm: '200px' },
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: { xs: 'center', sm: 'start' },
                            flexDirection: 'column',
                        }}
                    >
                        <Box>
                            <img
                                src={Assets.images.logoLight}
                                alt='logo'
                                width={150}
                                style={{ marginLeft: '12px' }}
                            />
                            <SocialMediaMenu />
                        </Box>
                        <Box sx={{ display: { xs: 'none', sm: 'inline' } }}>
                            <MadeWithRotate />
                        </Box>
                    </Box>

                    <RightMenu />
                </Box>
                <Divider
                    sx={{
                        my: 3,
                        mt: 4,
                        borderColor: '#ffffff88',
                        borderWidth: '0px',
                        borderTopWidth: '1px',
                    }}
                />
                <Typography color={'white'} fontSize={14} textAlign={'center'}>
                    © 2024 FluxGen Sustainable Technologies
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
